import Axios from "@/utils/axios";

export default {
    namespaced: true,
    state: typeof localStorage.getItem('cleaning-cart') !== "undefined" && localStorage.getItem('cleaning-cart') !== null ? JSON.parse(localStorage.getItem('cleaning-cart')) : {
        cleaningType: null,
        selectedServices: {},
        totalPrice: 0,
        services: [],
        servicesCacheByKey: {}
    },
    mutations: {
        ADD_MOVING_SERVICE(state, payload) {
            Object.keys(state.selectedServices).forEach(key => {
                if (key.substring(0, 15) !== 'moving-service-') {
                    delete state.selectedServices[key];
                }
            });

            if (state.selectedServices.hasOwnProperty('moving-transport-service')) {
                delete state.selectedServices['moving-transport-service'];
            }

            state.selectedServices[payload.serviceKey] = payload;
        },
        SET_CLEANING_SERVICES(state, payload) {
            state.services = payload;
        },
        ADD_SUBSCRIPTION_SERVICE(state, payload) {
            state.selectedServices = {}
            state.selectedServices[payload.serviceKey] = payload;
        },
        ADD_SERVICE(state, id) {
            //Delete subscription service if it was added before
            if (state.selectedServices.hasOwnProperty('subscription-cleaning-service')) {
                delete state.selectedServices['subscription-cleaning-service'];
            }

            if (!state.selectedServices.hasOwnProperty(id)) {
                let service = null;
                state.services.forEach(category => {
                    category.items.forEach(serviceItem => {
                        if (serviceItem.serviceKey === id) {
                            service = serviceItem;
                            service.quantity = 1;
                        }
                    });
                })

                if (service) {
                    state.selectedServices[id] = service;
                }
            }
            localStorage.setItem('cleaning-cart', JSON.stringify(state));
        },
        REMOVE_SERVICE(state, id) {
            const currentState = state;
            delete currentState.selectedServices[id];
            state = currentState;
            localStorage.setItem('cleaning-cart', JSON.stringify(state));
        },
        DELETE_ALL_SELECTED_SERVICES(state) {
            state.selectedServices = {};
            localStorage.setItem('cleaning-cart', JSON.stringify(state));
        },
        UPDATE_TOTAL_PRICE(state) {
            state.totalPrice = 0;
            Object.keys(state.selectedServices).forEach(serviceKey => {
                const service = state.selectedServices[serviceKey];
                const severity= service.hasOwnProperty('severity') ? service.severity : 1;

                let severitymultiplicator = 1
                switch (severity) {
                    case "2":
                        severitymultiplicator = 1.3
                        break;
                    case "3":
                        severitymultiplicator = 1.5
                        break;
                }

                let quantity = 1;
                if (service.hasOwnProperty('quantity')) {
                    quantity = service.quantity;
                }
                if (service.hasOwnProperty('hours')) {
                    quantity = service.hours;
                }
                if (service.hasOwnProperty('sqm')) {
                    quantity = service.sqm;
                }

                console.log(service);

                state.totalPrice += parseFloat(service.price) * parseFloat((quantity)) * severitymultiplicator;
            });
            localStorage.setItem('cleaning-cart', JSON.stringify(state));
        },
        UPDATE_CACHE_BY_KEY(state) {
            state.servicesCacheByKey = {};
            state.services.forEach(service => {
                service.items.forEach(item => {
                    state.servicesCacheByKey[item.serviceKey] = {
                        key: item.serviceKey,
                        inputType: item.hasOwnProperty('type') ? item.type : null,
                        service: service.name
                    }
                })
            })
        },
    },
    actions: {
        async calculateTotalPrice({commit}) {
            commit('UPDATE_CACHE_BY_KEY');
            commit('UPDATE_TOTAL_PRICE');
        },
        async addServiceToCart({commit}, id) {
            commit('ADD_SERVICE', id);
            commit('UPDATE_CACHE_BY_KEY');
            commit('UPDATE_TOTAL_PRICE');
        },
        async addSubscriptionServiceToCard({commit}, payload) {
            commit('ADD_SUBSCRIPTION_SERVICE', payload);
            commit('UPDATE_CACHE_BY_KEY');
            commit('UPDATE_TOTAL_PRICE');
        },
        async addMovingServiceToCard({commit}, payload) {
            commit('ADD_MOVING_SERVICE', payload);
            commit('UPDATE_CACHE_BY_KEY');
            commit('UPDATE_TOTAL_PRICE');
        },
        async removeServiceFromCart({commit}, id) {
            commit('REMOVE_SERVICE', id);
            commit('UPDATE_CACHE_BY_KEY');
            commit('UPDATE_TOTAL_PRICE');
        },
        async getAllServices({state, commit}) {
            if (state.services.length) {
                return state.services;
            }
            const response = await Axios.get('/api/cleaning/one-time/services');
            commit('SET_CLEANING_SERVICES', response.data);
            return response.data;
        },
        async deleteAllSelectedService({commit}) {
            commit('DELETE_ALL_SELECTED_SERVICES');
            commit('UPDATE_TOTAL_PRICE');
        },
        async makeOrder({commit}, payload) {
            const response = await Axios.post('/api/cleaning/one-time/order', payload);
            return response.data;
        },
        async makeDeepCleaningOrder({commit}, payload) {
            const response = await Axios.post('/api/cleaning/deep/order', payload);
            return response.data;
        },
    }
};