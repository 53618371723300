<template>
    <Header/>
    <div class="router-container">
        <router-view :key="$route.path"/>
    </div>
    <Footer v-if="showFooter" class="mt-5"/>
    <MarketplaceRoomsModal v-if="$store.state.utils.isMarketplaceRoomsModalOpen"/>
</template>

<script>
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/HeaderDesktop/Header.vue";
import {setLang} from "@/i18n";
import MarketplaceRoomsModal from "./components/Components/MarketplaceRoomsModal/MarketplaceRoomsModal";
import NotificationModal from "./components/Modal/NotificationModal";
import LoginModal from "./components/Modal/LoginModal";

export default {
    name: 'App',
    components: {LoginModal, NotificationModal, Header, Footer, MarketplaceRoomsModal},
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Sakvartirantite.com',
        // all titles will be injected into this template
        titleTemplate: 'Sakvartirantite.com'
    },
    watch: {
        '$route'() {
            if (this.$route.hasOwnProperty('meta') && this.$route.meta.hasOwnProperty('showFooter')) {
            this.showFooter = this.$route.meta.showFooter === true;
            }
        }
    },
    data() {
        return {
            showFooter: true,
            resizeListener: null,
        }
    },
    async created() {
        // set default lang
        let defaultLang = window.localStorage.getItem('defaultLang') || 'bg';
        if (defaultLang) {
            setLang(defaultLang)
        }

        await this.$store.dispatch("user/loadAuthenticatedUser");
    },
}
</script>

<style lang="scss">
@import "~@/assets/css/variables.scss";


html {
    height: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

body {
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
}

.mt-6 {
    margin-top: 4rem !important
}

.mt-7 {
    margin-top: 5rem !important
}

.pt-7 {
    padding-top: 5rem !important
}

body {
    font-family: $font-regular;
}

#app {
    font-family: $font-regular;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

.container-fluid {

}

.svg-icon {
    width: 2em;
    height: 2em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
    fill: #4c4d4f;
}

.svg-icon circle {
    stroke: #4c4d4f;
    stroke-width: 1;
}

.border-bottom {
    border-bottom: 1px solid #ccc;
}
</style>
